import { useEffect, useState } from "react";
import {Box, Stack, Typography} from "@mui/material";
import { Container } from "@mui/system";
import { m } from 'framer-motion';

export default function Maintenance() {
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    function calculateTimeLeft() {
        const targetDate = new Date("2024-08-31T23:59:00+02:00"); // Beogradsko vreme (CET/CEST)
        const now = new Date();
        const difference = targetDate - now;

        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, '0'),
                minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(2, '0'),
                seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, '0'),
            };
        }

        return timeLeft;
    }

    const logoAnimation = {
        initial: { scale: 0 },
        animate: { scale: 1, transition: { duration: 0.5 } },
        whileHover: { scale: 1.1 }
    };

    const textAnimation = {
        initial: { y: 20, opacity: 0 },
        animate: { y: 0, opacity: 1, transition: { duration: 0.5, delay: 0.5 } }
    };

    const digitAnimation = {
        initial: { opacity: 0, y: -20 },
        animate: { opacity: 1, y: 0, transition: { duration: 0.5 } },
    };

    return (
        <Container disableGutters
                   sx={{
                       display: 'flex',
                       flex: 1,
                       minWidth: "100%",
                       justifyContent: 'center',
                       alignItems: 'center',
                       height: '100vh',
                       padding: 0,
                       margin: 0,
                       maxWidth: '100%'
                   }}>
            <Box sx={{
                padding: 0,
                margin: 0,
                backgroundImage: 'url("/assets/images/header.png")',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                width: '100vw',
                height: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <m.div
                    variants={logoAnimation}
                    initial="initial"
                    animate="animate"
                    whileHover="whileHover"
                    style={{ width: '364px', height: '148px', backgroundImage: 'url("/assets/images/buska-logo.png")', backgroundSize: 'cover', backgroundPosition: 'center', display: 'flex' }}
                />
                <m.div
                    variants={textAnimation}
                    initial="initial"
                    animate="animate"
                    style={{ textAlign: 'center' }}
                >
                    <Stack marginTop={3} width={1} justifyContent="center" alignItems="center">
                        <Typography sx={{color: "white", fontSize: 30, fontWeight: 500}}>Buska kreće za:</Typography>
                    </Stack>

                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>


                        <m.div variants={digitAnimation} initial="initial" animate="animate">
                            <Typography variant="h2" sx={{ color: 'white', fontFamily: 'monospace' }}>
                                {timeLeft.days}:
                            </Typography>
                        </m.div>
                        <m.div variants={digitAnimation} initial="initial" animate="animate">
                            <Typography variant="h2" sx={{ color: 'white', fontFamily: 'monospace' }}>
                                {timeLeft.hours}:
                            </Typography>
                        </m.div>
                        <m.div variants={digitAnimation} initial="initial" animate="animate">
                            <Typography variant="h2" sx={{ color: 'white', fontFamily: 'monospace' }}>
                                {timeLeft.minutes}:
                            </Typography>
                        </m.div>
                        <m.div variants={digitAnimation} initial="initial" animate="animate">
                            <Typography variant="h2" sx={{ color: 'white', fontFamily: 'monospace' }}>
                                {timeLeft.seconds}
                            </Typography>
                        </m.div>
                    </Box>
                </m.div>
            </Box>
        </Container>
    );
}
